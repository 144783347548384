<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.suggestion.title") }}
          <v-spacer />
          <id-button class="mr-2" @click="onCreate">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("hr.suggestion.add_suggestion") }}
          </id-button>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template #item="row">
            <tr>
              <td>
                <employee-profile :employee="row.item.owner" />
              </td>
              <td>
                {{ row.item.category }}
              </td>
              <td>{{ row.item.body }}</td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-31 09:30:38
   * @modify date 2020-12-31 09:30:38
   * @desc Suggestions
   */
  import { SUGGESTIONS } from "./query";

  export default {
    name: "Suggestions",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          owner: vm.$store.state.auth.user.id,
          notificationReceiver: vm.$store.state.auth.user.id
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.suggestion.owner"),
            value: "owner",
            align: "start",
            sortable: false,
            class: ["primary--text"],
            width: "20%"
          },
          {
            text: vm.$t("hr.suggestion.category"),
            value: "category",
            align: "start",
            sortable: false,
            class: ["primary--text"],
            width: "20%"
          },
          {
            text: vm.$t("hr.suggestion.body"),
            value: "body",
            align: "start",
            sortable: false,
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      fetchItems() {
        this.table.loading = true;
        this.$apollo
          .query({
            query: SUGGESTIONS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateSuggestions.content || [];
              this.table.totalItems = data.filterWithPaginateSuggestions.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.replace({ name: "suggestion_create" });
      }
    }
  };
</script>

<style scoped></style>
